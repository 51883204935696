<template>
  <div class="fill-height">
    <Transition>
      <div @wheel="wheelPrevent" ref="mobileMenu" class="mobile-menu" v-if="showMenu">
        <v-card class="fill-height" tile>
          <v-card-title>
            <h2>Menu</h2>
            <v-spacer></v-spacer>
            <v-btn icon x-large @click="hideMenu">
              <v-icon v-text="'fas fa-xmark'"></v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  class="menu-item"
                  v-for="(item, i) in items"
                  :key="i"
                  :to="{ name: item.routeName }"
                  @click="hideMenu"
                >
                  <v-list-item-icon class="menu-item--icon d-flex justify-center">
                    <v-icon size="25" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" class="menu-item--text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </div>
    </Transition>
    <v-app-bar app height="60" elevate-on-scroll>
      <v-app-bar-title class="title px-0">
        <v-icon v-text="'fab fa-superpowers font-weight-bold'" left color="#2b425c" :size="24"></v-icon>
        <router-link :to="{ name: 'garage' }"> Super App </router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="showMenu = true" v-if="isAuthenticated" color="#2b425c">
        <v-icon v-text="'fas fa-bars'"></v-icon>
      </v-btn>
    </v-app-bar>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOGOUT } from '@/store/modules/auth.module';

export default {
  name: 'EndUserBase',
  data() {
    return {
      showMenu: false,
      selectedItem: 1,
      items: [
        { text: 'Garajım', icon: 'fas fa-square-parking', routeName: 'garage' },
        { text: 'Hatırlatmalar', icon: 'fas fa-bell', routeName: 'reminder' },
        { text: 'Hesabım', icon: 'fas fa-person', routeName: 'account' },
        { text: 'Güvenli Çıkış', icon: 'fas fa-power-off', routeName: 'logout' },
      ],
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  created() {
    this.$nextTick(() => {
      // if (!this.isAuthenticated) this.logoutUser();
    });
  },
  methods: {
    ...mapActions({
      logout: LOGOUT,
    }),
    logoutUser() {
      this.logout().then(() => {
        this.$router.push({ name: 'login' });
      });
    },
    action(item) {
      item.action();
      this.showMenu = false;
    },
    wheelPrevent(e) {
      if (e.deltaY > 0 || e.deltaY < 0) e.preventDefault();
      else if (e.wheelDeltaY < 0 || e.wheelDeltaY > 0) e.preventDefault();
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  watch: {
    isAuthenticated: {
      handler() {
        // if (!this.isAuthenticated) this.logoutUser();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  flex-grow: 14;
  text-overflow: show;

  a {
    color: #2b425c !important;
    text-decoration: none !important;
  }
  // border: 1px solid red;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.mobile-menu {
  position: fixed;
  overflow: hidden;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;

  .menu-item {
    &--icon {
      width: 25px !important;
    }
    &--text {
      font-size: 20px;
      font-weight: 300;
    }
  }
}
</style>
